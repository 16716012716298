<template>
  <div class="photo-container">
    <header>
      <el-button type="success" size="small" @click="open('add')" icon="el-icon-plus" v-if="isAdmin">添加照片</el-button>
    </header>
    <main>
      <el-row :gutter="20">
        <el-col :span="12" :offset="0" v-for="(item,i) in photoList" :key="i">
          <el-card class="box-card" shadow="hover">
            <div slot="header" class="clearfix" style="text-align:left">
              <span>描述:{{item.memo}}</span>
              <el-tooltip content="删除" placement="top" effect="dark" v-if="isAdmin">
                <el-button style="float:right;margin-right:5px;color:#F56C6C; padding: 3px 0;" type="text" icon="el-icon-delete" @click="delPhoto(item.id)"></el-button>
              </el-tooltip>
              <el-tooltip content="修改" placement="top" effect="dark" v-if="isAdmin">
                <el-button style="float:right;margin-right:5px;color:#E6A23C; padding: 3px 0;" type="text" icon="el-icon-edit" @click="open('edit',item)"></el-button>
              </el-tooltip>
            </div>
            <el-image :src="item.imageUrl" fit="fill" :lazy="true" :preview-src-list="[item.imageUrl]"></el-image>
          </el-card>
        </el-col>
      </el-row>
    </main>
    <el-dialog :title="optFlag?'添加照片':'修改照片'" :visible.sync="photoShow" width="40%" @close="clear" v-dialogDrag :close-on-click-modal="false">
      <el-form :model="photo" ref="photoForm" :rules="rules" label-width="100px">
        <el-form-item label="描述">
          <el-input v-model="photo.memo" type="textarea" :maxlength="200" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="是否为主照片">
          <el-select v-model="photo.isMain" placeholder="请选择是否为主照片" style="float:left">
            <el-option v-for="item in [{id:0,text:'是'},{id:1,text:'否'}]" :key="item.id" :label="item.text" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上传图片">
          <el-upload class="upload-demo" ref="upload" drag action="http://jaxf.jjxsw.vip:9002/app-version/uploadFile" :limit="1" :headers="{Authorization:token}" :on-success="uploadSuccess" :on-error="uploadError">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text"><em>点击上传</em></div>
            <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过2M</div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span>
        <el-button @click="photoShow = false">取 消</el-button>
        <el-button type="primary" v-show="optFlag" @click="addPhoto">添 加</el-button>
        <el-button type="primary" v-show="!optFlag" @click="updatePhoto">修 改</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  props: {
    companyId: {
      type: Number,
      default: 0
    },
    isAdmin: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      photoList: [],
      photoShow: false,
      optFlag: true,
      photo: {
        meno: '',
        isMain: '',
        imageUrl: ''
      },
      photoCopy: {},
      photoId: '',
      rules: {
        isMain: [
          { required: true, message: '请选择是否为主照片', trigger: 'change' }
        ]
      },
      token: ''
    }
  },
  created () {
    this.initCard()
    this.photoCopy = JSON.stringify(this.photo)
    this.token = sessionStorage.getItem('token')
  },
  methods: {
    async initCard () {
      const data = {}
      if (this.companyId !== 0) {
        data.unitId = this.companyId
      }
      const { data: result } = await this.$axios.get('/realScenePhoto/list', { params: data })
      if (result.code === 200) {
        this.photoList = result.data
      }
    },
    open (flag, row) {
      switch (flag) {
        case 'add':
          this.photoShow = true
          this.$refs.upload.clearFiles()
          break
        case 'edit':
          this.photoShow = true
          this.$refs.upload.clearFiles()
          this.photoId = row.id
          this.photo = JSON.parse(JSON.stringify(row))
          break
      }
    },
    async addPhoto () {
      this.$refs.photoForm.validate(async valid => {
        if (valid) {
          const { data: result } = await this.$axios.post('/realScenePhoto/add', this.photo)
          if (result.code === 200) {
            this.$message.success('添加成功')
            this.photoShow = false
            this.initCard()
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    },
    async updatePhoto () {
      this.$refs.photoForm.validate(async valid => {
        if (valid) {
          const { data: result } = await this.$axios.put(`/realScenePhoto/edit/${this.photoId}`, this.photo)
          if (result.code === 200) {
            this.$message.success('添加成功')
            this.photoShow = false
            this.initCard()
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    },
    // 删除某条数据
    async delPhoto (id) {
      const confirm = await this.$confirm('此操作将永久删除该照片, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirm === 'confirm') {
        const { data: result } = await this.$axios.delete(`/realScenePhoto/del/${id}`)
        if (result.code === 200) {
          this.$message.success('删除成功')
          this.initCard()
        } else {
          this.$message.error(result.msg)
        }
      }
    },
    uploadSuccess (res) {
      if (res.code === 200) {
        this.photo.imageUrl = res.data
        this.$message.success('上传成功')
      }
    },
    uploadError (err) {
      if (err) {
        this.$message.error('上传失败')
      }
    },
    clear () {
      this.photo = {
        meno: '',
        isMain: '',
        imageUrl: ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.photo-container {
  width: 100%;
  height: 100%;
  padding: 0 10px 0 10px;
  box-sizing: border-box;
  header {
    height: 45px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5px;
  }
  main {
    width: 100%;
    .el-card {
      box-sizing: border-box;
      margin-bottom: 20px;
      .el-image {
        height: 330px;
      }
    }
    .el-card:nth-child(2n) {
      margin-right: 0;
    }
  }
}
</style>
